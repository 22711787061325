<template>
  <el-dialog class="operation_dialog " v-model="dialogVisible" :title="dialogVisibletitle" :close-on-click-modal="false" width="560px">
    <template v-if="dialogVisibletitle === '新增个人' || dialogVisibletitle === '修改个人'">
      <div class="dp-f">
        <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" status-icon label-width="90px"
          class="demo-ruleForm w100" label-position="left">
          <el-form-item label="姓名" prop='name'>
            <el-input v-model="ruleForm.name" :maxlength="config.inputMaxlength" show-word-limit placeholder="请输入姓名" />
          </el-form-item>
          <el-form-item label="联系方式" prop='code' class="is-required">
            <el-input v-model="ruleForm.code" :maxlength="config.inputMaxlength" show-word-limit
              placeholder="请输入联系人的手机号" />
          </el-form-item>
        </el-form>
      </div>
    </template>
    <template #footer>
      <span class="dialog-footer dp-f fd-rr">
        <oabutton class="searcML" width='120' height='40' title="确定" CSStype=2 @buttonclick="submitForm()"
          v-model:loading="dialogloading"></oabutton>
        <oabutton class="searcML" width='120' height='40' title="取消" CSStype=3 @buttonclick="dialogVisible = false">
        </oabutton>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import { reactive, ref, unref, nextTick, defineEmits } from 'vue'
import { objdata_ret, objdata_set } from "@/utils/server/format";
import { handleMessage } from "@/utils/server/confirm";
import { httpToken } from "@/utils/request";
import { validPhone } from "@/utils/server/validate.js";
import qs from "qs";
const dialogloading = ref(false);// 弹框按钮loading 显示
const dialogVisible = ref(false)//弹框显示
const dialogVisibletitle = ref(false)//弹框标题
const emit = defineEmits(['Emit'])
const id = ref(0);//操作的id
const state = reactive({

})
const ruleFormRef = ref(null);//表单ref
const ruleForm = reactive({//from 表单数据
  name: '',//姓名
  code: '',//联系方式（手机号）
})
// 校验手机号格式
const validPhoneData = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入联系方式(手机号)'))
  } else {
    if (validPhone(value)) {
      callback()
    } else
      callback(new Error('手机号格式错误'))
  }
}
const rules = reactive({//from 表单校验
  name: [
    { required: true, message: '请输入姓名', trigger: 'blur' },
  ],
  code: [
    { validator: validPhoneData, trigger: 'blur' }
  ],
})
// 表单重置
let fromDataReset = (() => {
  objdata_ret(ruleForm)
})
let add = (() => {
  fromDataReset()
  dialogloading.value = false
  dialogVisibletitle.value = '新增个人'
  dialogVisible.value = true
  nextTick(() => {
    unref(ruleFormRef).clearValidate()//清除表单验证结果
  })
})
let editclick = ((row) => {
  fromDataReset()
  objdata_set(ruleForm, row)
  id.value = row.id
  dialogloading.value = false
  dialogVisibletitle.value = '修改个人'
  dialogVisible.value = true
  nextTick(() => {
    unref(ruleFormRef).clearValidate()//清除表单验证结果
  })
})
// 表单提交
const submitForm = async () => {
  dialogloading.value = true
  const form = unref(ruleFormRef)
  if (!form) {
    dialogloading.value = false
    return
  }
  await form.validate((valid) => {
    if (valid) {
      if (dialogVisibletitle.value === '新增个人') {
        dataGeneration({
          ...ruleForm,
          type: 0
        })
      } else if (dialogVisibletitle.value === '修改个人') {
        dataGeneration({
          id: id.value,
          ...ruleForm,
          type: 0
        }, '/admin/addressbook/update')
      }
    } else {
      dialogloading.value = false
    }
  })
}
// 数据生成
let dataGeneration = ((json, url = '/admin/addressbook/add') => {
  httpToken({
    method: "post",
    url: url,
    data: json,
  }).then((res) => {
    dialogloading.value = false
    dialogVisible.value = false
    emit('Emit')
  }).catch(() => {
    dialogloading.value = false
  })
})
defineExpose({
  add,
  editclick,
});
</script>